<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <!-- <v-img
            :src="require('@/assets/images/logos/logo.png')"
            max-height="72px"
            max-width="213px"
            alt="logo"
            contain
            class="me-3"
          ></v-img> -->
          <h2>CMS Login</h2>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form v-model="formValid" :disabled="processing" @submit.prevent="formValid && submitLogin()">
            <v-text-field
              v-model="email"
              class="mb-2"
              outlined
              label="電郵"
              :rules="[v => !!v || '請輸入電郵']"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="密碼"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :rules="[v => !!v || '請輸入電郵']"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-btn
              :loading="processing"
              :disabled="!formValid"
              block
              elevation="0"
              color="primary"
              class="mt-6"
              type="submit"
              @click="formValid && submitLogin()"
            >
              登入
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <v-snackbar v-model="loginError" timeout="4000" color="error" top>
      <div class="d-flex align-center">
        <v-icon class="mr-2" color="white">
          {{ icons.mdiAlertOctagon }}
        </v-icon>
        電郵或密碼錯誤
      </div>
      <template #action="{ attrs }">
        <v-btn color="white" icon v-bind="attrs" @click="loginError = false">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline, mdiClose, mdiAlertOctagon } from '@mdi/js'
import Cookies from 'js-cookie'

export default {
  name: 'Login',
  // data
  data: () => ({
    processing: false,
    formValid: false,
    loginError: false,
    isPasswordVisible: false,
    email: '',
    password: '',
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
      mdiClose,
      mdiAlertOctagon,
    },
  }),
  // first load
  mounted() {},
  // functions
  methods: {
    async submitLogin() {
      if (!this.processing) {
        this.processing = true

        const payload = {
          email: this.email,
          password: this.password,
        }
        try {
          const data = await this.$XHR.api('login', payload)
          this.$func.log('---login---')
          this.$func.log(data)

          Cookies.set('CANDY_LOGISTICS_CMS_LID', JSON.stringify(data), {
            path: '/',
            expires: 120,
            sameSite: 'strict',
            secure: true, // TODO:
          })

          this.$router.replace({ name: 'PickUp' })
        } catch (error) {
          this.$func.log(error)
          this.loginError = true
          this.processing = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
